import React from 'react';
import {NewsLayout} from 'sections/News/';
import useNewsData from 'shared/Hooks/News/useNewsData';
import {Images} from 'src/sections/News/NewsLayout/Images';
import {TextBlock} from 'src/sections/News/NewsLayout/TextBlock';
import {NEWS_55} from 'types/News';

const Article = () => {
  const data = useNewsData(NEWS_55);

  return (
    <NewsLayout data={data}>
      <Images data={data.images.first}/>
      <TextBlock data={data.texts.first}/>
      <TextBlock data={data.texts.second}/>
      <TextBlock data={data.texts.third}/>
      <TextBlock data={data.texts.fourth}/>
    </NewsLayout>
  );
};

export default Article;
